var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['icon__background',{'icon__background-primary' : _vm.background === 'primary'},
																	{'icon__background-secondary' : _vm.background === 'secondary'},
																	{'icon__background-tertiary' : _vm.background === 'tertiary'},
																	{'icon__background-dark' : _vm.background === 'dark'},
																	{'icon__background-light' : _vm.background === 'light'},
																	{'icon__background--size-small' : _vm.size === 'small'},
																	{'icon__background--size-medium' : _vm.size === 'medium'},
																	{'icon__background--size-large' : _vm.size === 'large'},
																	{'icon__background--border' : _vm.border}]},[_c('span',{class:['material-icons-outlined', 'icon', {'icon--color-primary' : _vm.color === 'primary'},
																										{'icon--color-secondary' : _vm.color === 'secondary'},
																										{'icon--color-tertiary' : _vm.color === 'tertiary'},
																										{'icon--color-dark' : _vm.color === 'dark'},
																										{'icon--color-light' : _vm.color === 'light'}]},[_vm._v(_vm._s(_vm.icon))])])}
var staticRenderFns = []

export { render, staticRenderFns }