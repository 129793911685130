<template>
	<div :class="['icon__background',{'icon__background-primary' : background === 'primary'},
																		{'icon__background-secondary' : background === 'secondary'},
																		{'icon__background-tertiary' : background === 'tertiary'},
																		{'icon__background-dark' : background === 'dark'},
																		{'icon__background-light' : background === 'light'},
																		{'icon__background--size-small' : size === 'small'},
																		{'icon__background--size-medium' : size === 'medium'},
																		{'icon__background--size-large' : size === 'large'},
																		{'icon__background--border' : border}]">
		<span :class="['material-icons-outlined', 'icon', {'icon--color-primary' : color === 'primary'},
																											{'icon--color-secondary' : color === 'secondary'},
																											{'icon--color-tertiary' : color === 'tertiary'},
																											{'icon--color-dark' : color === 'dark'},
																											{'icon--color-light' : color === 'light'}]">{{ icon }}</span>
	</div>
</template>

<script>
export default {
  props: {
    icon: String,
		color: String,
		size: String,
		background: String,
		border: Boolean,
	},
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.icon__background{
	text-align: center;
	height: var(--icon-size);
}
.icon__background--border{
	border: var(--icon-background-border);
}
.icon__background-primary{
	background: var(--icon-background-primary)
}
.icon__background-secondary{
	background: var(--icon-background-secondary)
}
.icon__background-tertiary{
	background: var(--icon-background-tertiary)
}
.icon__background-dark{
	background: var(--icon-background-dark)
}
.icon__background-light{
	background: var(--icon-background-light)
}

.icon{
	font-size: var(--icon-size);
	width: auto;
}
.icon__background--size-small{
	height: var(--icon-background-small-height);
	width: var(--icon-background-small-width);
	border-radius: var(--icon-background-small-border-radius);

	.icon{
		line-height: var(--icon-background-small-height);
		font-size: var(--icon-small-size);
		width: var(--icon-background-small-width);
	}
}
.icon__background--size-medium{
	height: var(--icon-background-medium-height);
	width: var(--icon-background-medium-width);
	border-radius: var(--icon-background-medium-border-radius);

	.icon{
		line-height: var(--icon-background-medium-height);
		font-size: var(--icon-medium-size);
	}
}
.icon__background--size-large{
	height: var(--icon-background-large-height);
	width: var(--icon-background-large-width);
	border-radius: var(--icon-background-large-border-radius);

	.icon{
		line-height: var(--icon-background-large-height);
		font-size: var(--icon-large-size);
	}
}

.icon--color-primary{
	color: var(--icon-color-primary)
}
.icon--color-secondary{
	color: var(--icon-color-secondary)
}
.icon--color-tertiary{
	color: var(--icon-color-tertiary)
}
.icon--color-dark{
	color: var(--icon-color-dark)
}
.icon--color-light{
	color: var(--icon-color-light)
}
</style>

<!-- Used in : SD -->